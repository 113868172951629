import {
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  CreditCardOutlined,
  FundOutlined,
  ShoppingCartOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'



const finansNavTree = [
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'sidenav.settings',
    icon: CreditCardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: '#',
        path: `${APP_PREFIX_PATH}/settings/websitesettings`,
        title: 'sidenav.settings',
        icon: CreditCardOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'settings',
            path: `${APP_PREFIX_PATH}/settings/`,
            title: 'sidenav.settings.website',
            icon: CreditCardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'marketsettings',
            path: `${APP_PREFIX_PATH}/settings/marketsettings`,
            title: 'sidenav.settings.market',
            icon: CreditCardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'usersettings',
            path: `${APP_PREFIX_PATH}/settings/usersettings`,
            title: 'sidenav.settings.user',
            icon: ClockCircleOutlined,
            breadcrumb: true,
            submenu: []
          }
        ]
      }
    ]
  },
  {
    key: 'staking',
    path: `${APP_PREFIX_PATH}/`,
    title: 'staking',
    icon: CreditCardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'stakepools',
        path: `${APP_PREFIX_PATH}/finans/odemeler`,
        title: 'stakepools',
        icon: CreditCardOutlined,
        breadcrumb: true,
        submenu: [

          {
            key: 'stakepools',
            path: `${APP_PREFIX_PATH}/stakepools`,
            title: 'stakepools',
            icon: ClockCircleOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'stakes',
            path: `${APP_PREFIX_PATH}/stakes`,
            title: 'stakes',
            icon: ClockCircleOutlined,
            breadcrumb: true,
            submenu: []
          }
        ]
      },

    ]
  },
  {
    key: 'nftstaking',
    path: `${APP_PREFIX_PATH}/`,
    title: 'nftstaking',
    icon: CreditCardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'nftstakepools',
        path: `${APP_PREFIX_PATH}/nftstakepools`,
        title: 'nftstakepools',
        icon: CreditCardOutlined,
        breadcrumb: true,
        submenu: [

          {
            key: 'nftstakepools',
            path: `${APP_PREFIX_PATH}/nftstakepools`,
            title: 'nftstakepools',
            icon: ClockCircleOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'nftstakes',
            path: `${APP_PREFIX_PATH}/nftstakes`,
            title: 'nftstakes',
            icon: ClockCircleOutlined,
            breadcrumb: true,
            submenu: []
          }
        ]
      },

    ]
  },
  {
    key: 'pairs',
    path: `${APP_PREFIX_PATH}/pairs`,
    title: 'coin_pairs',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'currencies',
    path: `${APP_PREFIX_PATH}/currencies`,
    title: 'currencies',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'balances',
    path: `${APP_PREFIX_PATH}/balances`,
    title: 'balances',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'users',
    path: `${APP_PREFIX_PATH}/users`,
    title: 'sidenav.users',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'languages',
    path: `${APP_PREFIX_PATH}/languages`,
    title: 'sidenav.languages',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [],
  }


  ]






const navigationConfig = [

  ...finansNavTree


]

export default navigationConfig;
