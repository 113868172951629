import { NAV_TYPE_SIDE, DIR_LTR, SIDE_NAV_DARK} from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Megaland Staking';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/admin';
export const AUTH_PREFIX_PATH = '/auth';
export const WEB_CONFIG = {
	server:"https://dexserver.metagalaxyland.com",
}
export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_DARK,
	locale: 'tr',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#283142',
	headerNavColor: '#283142',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
