import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
export const AppViews = () => {


  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/pairs`} component={lazy(() => import(`./pairs`))} />
        <Route path={`${APP_PREFIX_PATH}/currencies`} component={lazy(() => import(`./currencies`))} />
        <Route path={`${APP_PREFIX_PATH}/stakepools`} component={lazy(() => import(`./stakepools`))} />
        <Route path={`${APP_PREFIX_PATH}/nftstakepools`} component={lazy(() => import(`./nftstakepools`))} />
        <Route path={`${APP_PREFIX_PATH}/stakes`} component={lazy(() => import(`./stakes`))} />
        <Route path={`${APP_PREFIX_PATH}/nftstakes`} component={lazy(() => import(`./nftstakes`))} />
        <Route path={`${APP_PREFIX_PATH}/balances`} component={lazy(() => import(`./balances`))} />
        <Route path={`${APP_PREFIX_PATH}/banks`} component={lazy(() => import(`./banks`))} />
        <Route path={`${APP_PREFIX_PATH}/bankaccounts`} component={lazy(() => import(`./bankaccounts`))} />
        <Route path={`${APP_PREFIX_PATH}/bankpayments/`} component={lazy(() => import(`./bankpayments`))} />
        <Route path={`${APP_PREFIX_PATH}/finans`} component={lazy(() => import(`./finans`))} />
        <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />
        <Route path={`${APP_PREFIX_PATH}/languages`} component={lazy(() => import(`./languages`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
